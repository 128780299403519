import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useHistory, useParams, useLocation } from "react-router-dom"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"


import {
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap"
import {
  fetchAppointmentsBasedOnId,
  getPatientById,
  getPetById,
  getPricingItemById,
} from "helpers/api_helper"
// import "./style.scss"
import PetDetailsCard from "./components/PetDetailCard"
// import PetCompleteDetails from "./components/PetCompleteDetails"
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PetDetails() {
  //meta title
  document.title = "Pet Parents"

  const [itemDetails, setItemDetails] = useState({})
  const [noDetails, setNoDetails] = useState(false);
  const [isPastAppointment, setIsPastAppointment] = useState(true);


  const { itemId } = useParams()
  const query = useQuery();
  let appointmentDate = query.get("date");
  appointmentDate = appointmentDate.split("/").reverse().join("-");

  useEffect(() => {
    if(appointmentDate){
      if(new Date(appointmentDate) < new Date()){
        setIsPastAppointment(true)
      }else{
        setIsPastAppointment(false)
      }
    }

  }, [])


  // fetchAppointmentsListBasedOnPetId
  // const toggleVertical = tab => {
  //   if (verticalActiveTab !== tab) {
  //     setverticalActiveTab(tab)
  //   }
  // }
  useEffect(() => {
    async function fetchData() {
      const detail = await getPetById(itemId)
      if (detail.status === 1 && detail.data) {
        setItemDetails(detail.data)
   

        // const petList = await getAllPetsByParentId(id)
        // setParentPets(petList.data)
        // setverticalActiveTab(petList.data[0]._id)
      } else {
        setNoDetails(true)
      }
    }
    fetchData()
  }, [])
  if (noDetails) {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs
              title="Appointment"
              breadcrumbItem={itemDetails?.name}
            />
            <Row>
              <Col xs="12">Invalid Parent!!</Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="mx-3">
          <Breadcrumbs
            title="Patients"
            titleLink="/patients"
            breadcrumbItem={itemDetails?.fullName}
          />
          </div>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PetDetailsCard
                    testDetails={itemDetails ? itemDetails : {}}
                    isPastAppointment={isPastAppointment}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
PetDetails.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default PetDetails

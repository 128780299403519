import axios from "axios"
import config from "config"

//apply base url for axios
const API_URL = "" //"http://15.206.64.27:9000/api/"

const axiosApi = axios.create({
  baseURL: "API_URL",
})

// export const BASE = "http://localhost:9000/"
// export const BASE = "https://api.supaw.co/"
export const BASE = "https://api-staging.supaw.co/"
let token = ""
const BASE_URL = BASE + "api/"
if (localStorage.getItem("authUser")) {
  token = JSON.parse(localStorage.getItem("authUser"))
} else if (window.location.pathname !== "/login") {
  window.location.assign("/login")
}

axiosApi.defaults.headers.common["Authorization"] = token?.token || ""
axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 501) {
      // Redirect the user to "/logout" when a 501 response is received
      window.location.assign("/logout")
    }
    return Promise.reject(error)
  }
)
// axiosApi.interceptors.response.use(
//   response => response,
//   error => Promise.reject(error)
// )
// axiosApi.interceptors.response.use(
//   (res) => res,
//   (err) => {
//     if (
//      err.response.status >500
//     ) {
//       localStorage.removeItem("authUser")
//       window.location.assign('/login');
//       return { data: {} };
//     }
//     return Promise.reject(err);
//   },
// );
export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

// Pets APIs

// export async function getGeneralPatients(config = {}) {
//   const url = BASE_URL + "pets/v1/getPets"
//   return await axiosApi.get(url, { ...config }).then(response => response.data)
// }

export async function getGeneralPatients(vetId, config = {}) {
  const url = `${BASE_URL}pets/v1/getPets?vetId=${vetId}`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function getParent(data, config = {}) {
  url = BASE_URL + "patient/v1/getPatientsByDoctorId"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
}

export async function getParentById(data, config = {}) {
  const url = BASE_URL + "patient/v1/getPatient"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
}

export async function getPatientById(data, config = {}) {
  const url = BASE_URL + `pets/v1/getPet/${data.petId}`
  return await axiosApi
    .get(url, { ...config })
    .then(response => response.data.data)
}

export async function getPetById(id, config = {}) {
  const url = `${BASE_URL}pets/v1/getPet/${id}`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}
export async function getAllPetsByParentId(id, config = {}) {
  const url = `${BASE_URL}pets/v1/getParentPets/${id}`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

//Get All Parents by vetId
export async function getAllParentsByVetId(data, config = {}) {
  const url = `${BASE_URL}patient/v1/getPatientsByDoctorId`
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
}

//Inventory API
export async function getInventory(config = {}) {
  const url = BASE_URL + "pets/v1/getPets"
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function getInventoryById(data, config = {}) {
  const url = BASE_URL + `pets/v1/getPet/${data.petId}`
  return await axiosApi
    .get(url, { ...config })
    .then(response => response.data.data)
}

// patient/v1/getUser
export const addNewPetParent = async (
  data,
  url = BASE_URL + "patient/v1/registration",
  config = {}
) => {
  try {
    return axiosApi
      .post(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}
// patient/v1/getUser
export const addNewPet = async (
  data,
  url = BASE_URL + "pets/v1/registration",
  config = {}
) => {
  try {
    return axiosApi
      .post(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

export const updatePet = async (
  petId,
  data,
  url = BASE_URL + `pets/v1/updatePet/${petId}`,
  config = {}
) => {
  try {
    return axiosApi
      .patch(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

export const deletePet = async (
  data,
  url = BASE_URL + "pets/v1/deletePet",
  config = {}
) => {
  try {
    return axiosApi
      .post(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}
export const deleteParent = async (
  data,
  url = BASE_URL + "patient/v1/deletePatient",
  config = {}
) => {
  try {
    return axiosApi
      .post(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

// export const updatePatient = async (
//   id,
//   data,
//   url = `${BASE_URL}patient/v1/userUpdate/${id}`,
//   config = {}
// ) => {
//   try {
//     return axiosApi
//       .patch(url, { ...data }, { ...config })
//       .then(response => response.data)
//   } catch (error) {
//     return { type: "error", message: error.response }
//   }
// }
export const updatePatient = async (
  parentId,
  data,
  url = BASE_URL + `patient/v1/userUpdate/${parentId}`,
  config = {}
) => {
  try {
    return axiosApi
      .patch(url, data, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

export const fetchPets = async () => {
  const { data } = await axios.get(`${BASE_URL}pets/v1/getPets`)
  return data
}

// User API
export const checkExistance = async (data, deviceToken, config = {}) => {
  data.role = "vet"
  const url = BASE_URL + "vet/v1/loginNormalUser"
  const finalData = { ...data, deviceToken }
  return await axiosApi
    .post(url, finalData, { ...config })
    .then(response => response.data)
  // const response = await axios.post(`${BASE_URL}vet/v1/loginNormalUser`, obj)
  // return response.data
}
export const getLoggedInUserDetails = async (data, config = {}) => {
  const url = BASE_URL + "vet/v1/getUser"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
  // const response = await axios.post(`${BASE_URL}vet/v1/getUser`, obj)
  // return response.data
}

export const googleLoginRegister = async (data, config = {}) => {
  const url = BASE_URL + "vet/v1/googleLogin"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
  // const response = await axios.post(`${BASE_URL}vet/v1/googleLogin`, obj)
  // return response.data
}

export const sendForgotPassword = async (data, config = {}) => {
  const url = BASE_URL + "vet/v1/send-forgot-password-link"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
  // const response = await axios.post-password-link`,
  //   obj(
  //   `${BASE_URL}vet/v1/send-forgot
  // )
  // return response.data
}

export const updatePassword = async (data, config = {}) => {
  const url = BASE_URL + "vet/v1/updatePassword"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
  // const response = await axios.post(`${BASE_URL}vet/v1/updatePassword`, obj)
  // return response.data
}
export const updateVet = async (
  _id,
  data,
  url = `${BASE_URL}vet/v1/userUpdate/${_id}`,
  config = {}
) => {
  try {
    return axiosApi
      .patch(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
  // const response = await axios.patch(`${BASE_URL}vet/v1/userUpdate/${_id}`, obj)
  // return response.data
}
export const updateVetTimings = async (
  _id,
  data,
  url = `${BASE_URL}vet/v1/userUpdateSchedule/${_id}`,
  config = {}
) => {
  try {
    return axiosApi
      .patch(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
  // const response = await axios.patch(
  //   `${BASE_URL}vet/v1/userUpdateSchedule/${_id}`,
  //   obj
  // )
  // return response.data
}
//Get  Timings by VetId

export async function getVetTimingsByVetId(vetId, config = {}) {
  const url = `${BASE_URL}vets/v1/getTimings/${vetId}`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}
export const registerVet = async (data, deviceToken, config = {}) => {
  console.log(data, "datattt")
  const url = BASE_URL + "vet/v1/registration"
  const finalData = { ...data, deviceToken }
  return await axiosApi
    .post(url, finalData, { ...config })
    .then(response => response.data)
  // const response = await axios.post(`${BASE_URL}vet/v1/registration`, obj)
  // return response.data
}
export const uploadAttachment = async (formData, config) => {
  try {
    const response = await axios.post(
      `${BASE_URL}vet/v1/uploadImages`,
      formData,
      { ...config }
    )
    console.log(response)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

// Appointments

export const fetchAppointments = async (config = {}) => {
  const url = BASE_URL + `Appointment/v1/todayAppointment`
  return await axiosApi.get(url, { ...config }).then(response => response.data)

  // const response = await axios.get(`${BASE_URL}Appointment/v1/todayAppointment`)
  // return response.data
}

export const fetchAppointmentsBasedOnDoctorId = async (id, config = {}) => {
  const url = `${BASE_URL}Appointment/v1/doctorTodayAppointment/${id}`

  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export const fetchAppointmentsBasedOnId = async (id, config = {}) => {
  const url = `${BASE_URL}Appointment/v1/getAppointment`
  return await axiosApi
    .post(url, { appointmentId: id }, { ...config })
    .then(response => response.data)
  // const response = await axios.post(
  //   `${BASE_URL}Appointment/v1/getAppointment`,
  //   { appointmentId: id },...config
  // )
  // return response.data
}
// Globals
export const getServiceType = async (config = {}) => {
  const url = BASE_URL + `ServiceList/v1/getServiceList`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
  // const response = await axios.get(`${BASE_URL}ServiceList/v1/getServiceList`)
  // return response.data
}
export const getPetTypes = async (config = {}) => {
  const url = BASE_URL + `PetTypes/v1/getPetTypes`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
  // const response = await axios.get(`${BASE_URL}PetTypes/v1/getPetTypes`)
  // return response.data
}
export const getPetBreed = async (id, config = {}) => {
  const url = BASE_URL + `Breeds/v1/getBreedsById/${id}`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
  // const response = await axios.get(`${BASE_URL}Breeds/v1/getBreedsById/${id}`)
  // return response.data
}
// Globals
export const updateVisit = async (
  id,
  data,
  url = `${BASE_URL}Appointment/v1/updateVisit/${id}`,
  config = {}
) => {
  try {
    return axiosApi
      .patch(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
  // const response = await axios.patch(
  //   `${BASE_URL}Appointment/v1/updateVisit/${id}`,
  //   obj
  // )
  // return response.data
}
export const updatePrescription = async (
  id,
  data,
  url = `${BASE_URL}Appointment/v1/updatePrescription/${id}`,
  config = {}
) => {
  try {
    return axiosApi
      .patch(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
  // const response = await axios.patch(
  //   `${BASE_URL}Appointment/v1/updatePrescription/${id}`,
  //   obj
  // )
  // return response.data
}
export const updateImaging = async (
  id,
  data,
  url = `${BASE_URL}Appointment/v1/addImaging/${id}`,
  config = {}
) => {
  try {
    return axiosApi
      .patch(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
  // const response = await axios.patch(
  //   `${BASE_URL}Appointment/v1/addImaging/${id}`,
  //   obj
  // )
  // return response.data
}

export const addAppointment = async (data, config = {}) => {
  const url = `${BASE_URL}Appointment/v1/createAppointment`

  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
}

export const updateAppointment = async (
  id,
  data,
  url = `${BASE_URL}Appointment/v1/editAppointment/${id}`,
  config = {}
) => {
  try {
    return axiosApi
      .patch(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
  // const response = await axios.patch(
  //   `${BASE_URL}Appointment/v1/editAppointment/${id}`,
  //   obj
  // )
  // return response.data
}

export const deleteAppointment = async (id, config = {}) => {
  const url = `${BASE_URL}Appointment/v1/deleteAppointment/`

  return await axiosApi
    .post(url, { appointmentId: id }, { ...config })
    .then(response => response.data)
  // const response = await axios.post(
  //   `${BASE_URL}Appointment/v1/deleteAppointment/`,
  //   {
  //     appointmentId: id,
  //   }
  // )
  // return response.data
}
export const getDiagnosisList = async (id, config = {}) => {
  const url = BASE_URL + `Diagnosis/v1/getDiagnosis`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
  // const response = await axios.get(`${BASE_URL}Diagnosis/v1/getDiagnosis`)
  // return response.data
}
export const getMedicineList = async (id, config = {}) => {
  const url = BASE_URL + `MedicineMaster/v1/getMedicineMaster`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
  // const response = await axios.get(
  //   `${BASE_URL}MedicineMaster/v1/getMedicineMaster`
  // )
  // return response.data
}

export const fetchAppointmentsListBasedOnPetId = async (id, config = {}) => {
  const url = BASE_URL + `Appointment/v1/getAppointmentsByPetId/${id}`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
  // const response = await axios.get(
  //   `${BASE_URL}Appointment/v1/getAppointmentsByPetId/${id}`
  //   // { appointmentId: id }
  // )
  // return response.data
}

// Timings
export const fetchTimings = async (id, data, config = {}) => {
  const url = `${BASE_URL}vets/v1/timings/${id}`
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
}

export const updateDayAvailabilityByDayIdAndDocId = async (
  data,
  config = {}
) => {
  const url = BASE_URL + "vets/v1/updateDayAvailabilityByDayIdAndDocId"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
  // const response = await axios.post(
  //   `${BASE_URL}vets/v1/updateDayAvailabilityByDayIdAndDocId`,
  //   data
  // )
  // return response.data
}

export const updateStartAndEndTimeByDayIdAndDocId = async (
  data,
  config = {}
) => {
  const url = BASE_URL + "vets/v1/updateStartAndEndTimeByDayIdAndDocId"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
  // const response = await axios.post(
  //   `${BASE_URL}vets/v1/updateStartAndEndTimeByDayIdAndDocId`,
  //   data
  // )
  // return response.data
}
//Update start and end time for a particular day
export const updateStartAndEndTimeByDayIdAndDocIdForParticularDay = async (
  data,
  config = {}
) => {
  const url =
    BASE_URL +
    `vets/v1/updateOneDayStartAndEndTimeByDayIdAndDocId/${data.doctorId}`
  try {
    const response = await axiosApi.post(url, data, { ...config })
    console.log("response.datainsideapi????", response)

    return response.data
  } catch (error) {
    throw error
  }
  // return await axiosApi
  //   .post(url, data, { ...config })
  //   .then(response => response.data)
  // const response = await axios.post(
  //   `${BASE_URL}vets/v1/updateStartAndEndTimeByDayIdAndDocId`,
  //   data
  // )
  // return response.data
}
// imaging and Labs

export const addImagingRequest = async (data, config = {}) => {
  const url = BASE_URL + "ImagingAndLab/v1/createImagingAndLab"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
  // const response = await axios.post(
  //   `${BASE_URL}ImagingAndLab/v1/createImagingAndLab`,
  //   data
  // )
  // return response.data
}
export const updateImagingRequest = async (data, config = {}) => {
  const url = BASE_URL + "ImagingAndLab/v1/updateImagingAndLab"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
  // const response = await axios.post(
  //   `${BASE_URL}ImagingAndLab/v1/updateImagingAndLab`,
  //   data
  // )
  // return response.data
}

export const getImagingAndLap = async (data, config = {}) => {
  const url = BASE_URL + "ImagingAndLab/v1/getImagingAndLabsByDoctorIdAndType"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
  // const response = await axios.post(
  //   `${BASE_URL}ImagingAndLab/v1/getImagingAndLabsByDoctorIdAndType`,
  //   data
  // )
  // return response.data
}

export const getImagingAndLabsBasedOnId = async (data, config = {}) => {
  // Based on Test id
  const url = BASE_URL + "ImagingAndLab/v1/getImagingAndLabsBasedOnId"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
}
// const response = await axios.post(
//   `${BASE_URL}ImagingAndLab/v1/getImagingAndLabsBasedOnId`,
//   data
// )
// return response.data
// }

export const getImagingAndLabsBasedOnPetId = async (data, config = {}) => {
  const url =
    BASE_URL + "ImagingAndLab/v1/getCompletedImagingAndLabsBasedOnPetId"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
  // Based on Test id
  // const response = await axios.post(
  //   `${BASE_URL}ImagingAndLab/v1/getCompletedImagingAndLabsBasedOnPetId`,
  //   data
  // )
  // return response.data
}
export const getAllVetSpecialization = async (id, config = {}) => {
  const url = `${BASE_URL}VeterinarySpecialties/v1/getAllVetSpecialization`
  return await axiosApi.get(url, { ...config }).then(response => response.data)

  // const response = await axios.get(
  //   `${BASE_URL}VeterinarySpecialties/v1/getAllVetSpecialization`
  // )
  // return response.data
}

//Pricing Category API methods

//fetch Pricing Ctegories
export async function getPricingCategoryByVetId(data, config = {}) {
  const url = BASE_URL + "PriceCategories/v1/getAllPriceCategoriesByVetId"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
}

// Creating new Pricing Categories
export const addNewPricingCategory = async (
  data,
  url = BASE_URL + "PriceCategories/v1/createPriceCategories",
  config = {}
) => {
  try {
    return axiosApi
      .post(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//Update Category name
export const updateCategory = async (
  categoryId,
  data,
  url = BASE_URL + `PriceCategories/v1/PriceCategoriesUpdate/${categoryId}`,
  config = {}
) => {
  try {
    return axiosApi
      .patch(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//Delete Category
export const deleteCategory = async (
  categoryId,
  url = BASE_URL + `PriceCategories/v1/deletePriceCategories/${categoryId}`,
  config = {}
) => {
  try {
    console.log("categoryId,", categoryId)

    return axiosApi
      .delete(url, { ...config }) // Spread the 'config' object, not 'data'
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//Get PricingCategory details by Id

export async function getPricingCategoryById(itemId, config = {}) {
  const url = `${BASE_URL}PriceCategories/v1/getPriceCategoriesById/${itemId}`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

//Pricng API Methods

//Fetching the price of a particular Category

export async function getAllPricingByVetIdAndCategoryId(data, config = {}) {
  const url = BASE_URL + "Pricing/v1/getAllPricingByVetId"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
}

// Creating new Pricing
export const createpricing = async (
  data,
  url = BASE_URL + "Pricing/v1/createPricing",
  config = {}
) => {
  try {
    return axiosApi
      .post(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//Updating the Pricing Details
export const updatepricing = async (
  pricingItemId,
  data,
  url = BASE_URL + `Pricing/v1/PricingUpdate/${pricingItemId}`,
  config = {}
) => {
  try {
    return axiosApi
      .patch(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//Deleting pricing
export const deletePricing = async (
  pricingItemId,
  url = BASE_URL + `Pricing/v1/deletePricing/${pricingItemId}`,
  config = {}
) => {
  try {
    return axiosApi
      .delete(url, { ...config }) // Spread the 'config' object, not 'data'
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//Get Pricing Item details by Id

export async function getPricingItemById(itemId, config = {}) {
  const url = `${BASE_URL}Pricing/v1/getPricingById/${itemId}`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

//Generic Pricing API Methods

//Get All  Categories of Type Generic by VetId

export async function getGenericCategoryByVetId(vetId, config = {}) {
  const url = `${BASE_URL}PriceCategories/v1/getAllGenericPricingCategories/${vetId}`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

//Inventory API Methods

//fetch All Inventory Items by vetId
export async function getInventoryitemsByVetId(data, config = {}) {
  const url = BASE_URL + "Inventory/v1/getAllInventoryByVetId"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
}

// Creating new Inventory Item
export const createInventoryItem = async (
  data,
  url = BASE_URL + "Inventory/v1/createInventory",
  config = {}
) => {
  try {
    return axiosApi
      .post(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//Updating the Inventory item Details
export const updateInventoryitem = async (
  pricingItemId,
  data,
  url = BASE_URL + `Inventory/v1/InventoryUpdate/${pricingItemId}`,
  config = {}
) => {
  try {
    return axiosApi
      .patch(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//Deleting the Inventory item
export const deleteInventoryItem = async (
  pricingItemId,
  url = BASE_URL + `Inventory/v1/deleteInventory/${pricingItemId}`,
  config = {}
) => {
  try {
    return axiosApi
      .delete(url, { ...config }) // Spread the 'config' object, not 'data'
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//Get the Inventory item Details by Id

export async function getinventoryItemById(itemId, config = {}) {
  const url = `${BASE_URL}Inventory/v1/getInventoryById/${itemId}`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

//  Manufacturer API Methods

//fetch All Manufacturer by vetId
export async function getManufacturersByVetId(data, config = {}) {
  const url = BASE_URL + "Manufactures/v1/getAllManufacturesByVetId"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
}

// Creating new Manufacturer
export const createManufacturer = async (
  data,
  url = BASE_URL + "Manufactures/v1/createManufactures",
  config = {}
) => {
  try {
    return axiosApi
      .post(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//Updating the Manufacturer Details
export const updateManufacturer = async (
  manufacturerId,
  data,
  url = BASE_URL + `Manufactures/v1/ManufacturesUpdate/${manufacturerId}`,
  config = {}
) => {
  try {
    return axiosApi
      .patch(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//Deleting the Manufacturer
export const deleteManufacturer = async (
  pricingItemId,
  url = BASE_URL + `Manufactures/v1/deleteManufacture/${pricingItemId}`,
  config = {}
) => {
  try {
    return axiosApi
      .delete(url, { ...config }) // Spread the 'config' object, not 'data'
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//Get the Manufacturer Details by Id

export async function getManufacturerById(itemId, config = {}) {
  const url = `${BASE_URL}Manufactures/v1/getManufactureById/${itemId}`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

//  Aisle API Methods

//fetch All Aisle by vetId
export async function getAislesByVetId(data, config = {}) {
  const url = BASE_URL + "AisleLocations/v1/getAllAisleLocationsByVetId"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
}

// Creating new Aisle
export const createAisle = async (
  data,
  url = BASE_URL + "AisleLocations/v1/createAisleLocations",
  config = {}
) => {
  try {
    return axiosApi
      .post(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//Updating the Aisle Details
export const updateAisle = async (
  aisleId,
  data,
  url = BASE_URL + `AisleLocations/v1/AisleLocationsUpdate/${aisleId}`,
  config = {}
) => {
  try {
    return axiosApi
      .patch(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//Deleting the Aisle
export const deleteAisle = async (
  pricingItemId,
  url = BASE_URL + `AisleLocations/v1/deleteAisleLocation/${pricingItemId}`,
  config = {}
) => {
  try {
    return axiosApi
      .delete(url, { ...config }) // Spread the 'config' object, not 'data'
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//Get the Aisle Details by Id

export async function getAisleById(itemId, config = {}) {
  const url = `${BASE_URL}AisleLocations/v1/getAisleLocationById/${itemId}`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

//  Inventory Type API Methods

//fetch All Inventory Type by vetId
export async function getInventoryTypeByVetId(data, config = {}) {
  const url = BASE_URL + "Types/v1/getAllTypesByVetId"
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
}

// Creating new Inventory Type
export const createInventoryType = async (
  data,
  url = BASE_URL + "Types/v1/createTypes",
  config = {}
) => {
  try {
    return axiosApi
      .post(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//Updating the Aisle Details
// export const updateInventoryType = async (
//   pricingItemId,
//   data,
//   url = BASE_URL + `Manufactures/v1/ManufacturesUpdate/${pricingItemId}`,
//   config = {}
// ) => {
//   try {
//     return axiosApi
//       .patch(url, { ...data }, { ...config })
//       .then(response => response.data)
//   } catch (error) {
//     return { type: "error", message: error.response }
//   }
// }

export const updateInventoryType = async (
  id,
  data,
  url = BASE_URL + `Types/v1/TypesUpdate/${id}`,
  config = {}
) => {
  try {
    return axiosApi
      .patch(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return {
      type: "error",
      message: error.response?.data?.message || "Something went wrong",
    }
  }
}

//Deleting the Inventory Type
export const deleteInventoryType = async (
  pricingItemId,
  url = BASE_URL + `Types/v1/deleteTypes/${pricingItemId}`,
  config = {}
) => {
  try {
    return axiosApi
      .delete(url, { ...config }) // Spread the 'config' object, not 'data'
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//Get the Inventory Type Details by Id

export async function getInventoryTypeById(itemId, config = {}) {
  const url = `${BASE_URL}Types/v1/getTypesById/${itemId}`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

//Dashboard API Methods
// Fetch Weekly Revenue from vetId
export const fetchWeeklyRevenueByVetId = async (
  data,
  url = BASE_URL + "Appointment/v1/weeklyClinicRevenue",
  config = {}
) => {
  try {
    return axiosApi
      .post(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

// Fetch Weekly Revenue from vetId
export const fetchWeeklyPatientCountByVetId = async (
  data,
  url = BASE_URL + "Appointment/v1/weeklyClinicPatientRevnue",
  config = {}
) => {
  try {
    return axiosApi
      .post(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

// Fetch Weekly Revenue from vetId
export const fetchYearlyRevenueByVetId = async (
  data,
  url = BASE_URL + "Appointment/v1/yearlyClinicRevenue",
  config = {}
) => {
  try {
    return axiosApi
      .post(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//Address master Data

// fetch all countries
export async function getCountries(config = {}) {
  const url = `${BASE_URL}WorldMasterData/v1/getAllMasterCountries`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

//fetch all state based on the country
export const fetchAllStateBasedOnCountry = async (
  data,
  url = BASE_URL + "WorldMasterData/v1/getAllMasterStatesBasedCountries",
  config = {}
) => {
  try {
    return axiosApi
      .post(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

//fetch all cities based on the country
export const fetchAllCityBasedOnCountry = async (
  data,
  url = BASE_URL + "WorldMasterData/v1/getAllMasterCitiesBasedState",
  config = {}
) => {
  try {
    return axiosApi
      .post(url, { ...data }, { ...config })
      .then(response => response.data)
  } catch (error) {
    return { type: "error", message: error.response }
  }
}


export const fetchDashboardApi = async (params) => {
  try {
    const response = await axiosApi.post(`${BASE_URL}vet/v1/dashboard`, params);
    return response.data
  } catch (error) {
    return { type: "error", message: error.response }
  }
}

export const fetchDashboardApiTwo = async (petType, doctorId, start, end) => {
  try {
    const response = await axiosApi.get(`${BASE_URL}vet/v1/analytics?petType=${petType}&doctorId=${doctorId}&end=${end}&start=${start}`);
    return response.data
  } catch (error) {
    return { type: "error", message: error.response }
  }
}
